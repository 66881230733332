<template>
    <div>
        <b-card class="m-2" no-body>
            <div class="m-2">
                <div style="text-align: center; margin-top: 50px; margin-bottom: 50px;">
                    <h2>{{ $t("users_app.reset_password") }}</h2>
                </div>
                <div style="margin:auto">
                    <b-row class="justify-content-md-center">
                        <b-col cols="4" md="4" style="text-align:center;">
                            <div>
                                <b-form-input type="password" class="d-inline-block mr-1"
                                    :placeholder="$t('users_app.new_password')" v-model="newPassword" />
                            </div>
                            <div class="mt-3 mb-5">
                                <b-form-input class="d-inline-block mr-1" type="password"
                                    :placeholder="$t('users_app.write_the_password_again')" v-model="passwordAgain" />
                            </div>
                            <div class="mt-3 " style="margin-bottom: 150px;">
                                <b-button variant="primary" style="float: left;">
                                    <span class="text-nowrap text-capitalize">{{
                                            $t("users_app.cancel")
                                    }}</span>
                                </b-button>
                                <b-button variant="primary" style="float: right;"
                                    :disabled='newPassword == "" || passwordAgain == "" ? true : false'
                                    @click="passwordEncrypt($route.params.id)">
                                    <span class="text-nowrap text-capitalize">{{
                                            $t("users_app.next")
                                    }}</span>
                                </b-button>
                            </div>
                        </b-col>
                    </b-row>
                </div>
            </div>
        </b-card>
    </div>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BButton,
    // BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BImg,
} from "bootstrap-vue";
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import useList from "./useList";
import Sidenav from "./Sidenav.vue";
import Filters from "./Filters.vue";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import sha256 from "js-sha256"


export default {
    components: {
        Sidenav,
        Filters,
        BCard,
        BRow,
        BCol,
        BFormInput,
        BButton,
        BTable,
        BDropdown,
        BDropdownItem,
        BPagination,
        BImg,
        vSelect,
    },

    setup(_, context) {
        const newPassword = ref("");
        const passwordAgain = ref("")
        const toast = useToast();
        const passwordEncrypt = function (idUser) {
            let password;
            if (newPassword.value == passwordAgain.value) {
                password = sha256(newPassword.value);
                const req = {
                    id: idUser,
                    params: {
                        password: password,
                    }
                }
                store.dispatch("user_app/edit", req)
                    .then((response) => {
                        if (response.success) {
                            toast({
                                component: ToastificationContent,
                                props: {
                                    title: "La contraseñas se actualizo correctamente",
                                    icon: "CheckIcon",
                                    variant: "success",
                                },
                            });
                            newPassword.value = "";
                            passwordAgain.value = "";
                        } else {
                            toast({
                                component: ToastificationContent,
                                props: {
                                    title: "La contraseñas no actualizo correctamente",
                                    icon: "AlertTriangleIcon",
                                    variant: "danger",
                                },
                            });
                        }
                    })
            }
            else {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: "Las contraseñas no son identicas",
                        icon: "AlertTriangleIcon",
                        variant: "danger",
                    },
                });
            }
        };
        return {
            passwordEncrypt,
            newPassword,
            passwordAgain,

        };
    },
};
</script>